import {Fragment} from 'react'
import Items from '../../../../components/Items/Items'
import Pagination from 'react-bootstrap/Pagination'
import Button from 'react-bootstrap/Button'
import {connect} from "react-redux";
import * as actionTypes from "../../../../store/actions";
import {FaRegTrashAlt} from 'react-icons/fa';
import Alert from "react-bootstrap/Alert";

const ItemsList = (props) => {

    const clickHandler = (e) => {
        let selectedIds = props.listItems.map((item) => {
            return item.id
        });

        if (!props.allItemsSelected) {
            props.addItems(selectedIds.filter(
                (item) => {
                    return !props.selectedItems.includes(item)
                }
            ));
        } else {
            props.deleteItems(selectedIds);
        }
        props.setAllItemsSelected(!props.allItemsSelected);
    };

    const clearHandler = () => {
        props.setAllItemsSelected(false);
        props.clearSelected();
    };

    const pagination = (scrollRef) => {
        const items = [];
        for (let number = 1; number < Math.min((props.pagingTotal / props.limit), 20) + 1; number++) {
            items.push(
                <Pagination.Item key={number} active={(number - 1) * props.limit === props.pagingStart - 1}
                                 onClick={() => {
                                     props.setOffset((number - 1) * props.limit);
                                     props.initItems();
                                     scrollRef.current.scrollIntoView()
                                 }}>
                    {number}
                </Pagination.Item>,
            );
        }
        if (props.pagingTotal > 0) {
            return (<Pagination>{items}</Pagination>);
        } else {
            return null
        }
    };

    return (
        <Fragment>

            <h6>
                <Button onClick={clickHandler} variant="light" disabled={props.listItems.length === 0 || props.destinationSellerId === props.sellerId}>
                    <input type='checkbox' checked={props.allItemsSelected} readOnly={true}/>
                </Button> Itens Selecionados: {props.selectedItems.length} {props.selectedItems.length > 0 ?
                <Button onClick={clearHandler} variant="light"><FaRegTrashAlt/> Limpar Seleção</Button> : null}
            </h6>
            <Items items={props.listItems}/>
            {pagination(props.newPageScrollToRef)}
            {props.sameSellerListingNotAllowedError ?
                <Alert variant='danger'>
                    <Alert.Heading>Anúncios duplicados são proibidos</Alert.Heading>
                    <p>Altere a conta de destino ou pesquise o anúncio de outro vendedor.
                    Mais informações na{' '}
                    <Alert.Link rel="noreferrer" target="_blank" href="https://www.mercadolivre.com.br/ajuda/An-ncios-duplicados_2517">central de ajuda do Mercadolivre</Alert.Link>
                    </p>
                </Alert> : null}
        </Fragment>
    )
};

const mapStateToProps = state => {
    return {
        selectedItems: state.item.selectedItems,
        fetchItemsFailed: state.item.fetchItemsFailed,
        listItems: state.item.listItems,
        pagingEnd: state.item.pagingEnd,
        pagingStart: state.item.pagingStart,
        pagingTotal: state.item.pagingTotal,
        offset: state.search.offset,
        limit: state.search.limit,
        sort: state.search.sort,
        searching: state.search.searching,
        allItemsSelected: state.item.allItemsSelected,
        destinationSellerId: state.replication.destinationSellerId,
        sellerId: state.item.sellerId,
        sameSellerListingNotAllowedError: state.item.sameSellerListingNotAllowedError,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setSort: (value) => dispatch(actionTypes.setSort(value)),
        setOffset: (value) => dispatch(actionTypes.setOffset(value)),
        setNickname: (value) => dispatch(actionTypes.setNickname(value)),
        setLimit: (value) => dispatch(actionTypes.setLimit(value)),
        initItems: (value) => dispatch(actionTypes.initItems()),
        addItems: (itemIds) => dispatch(actionTypes.addItems(itemIds)),
        deleteItems: (itemIds) => dispatch(actionTypes.deleteItems(itemIds)),
        setAllItemsSelected: (value) => dispatch(actionTypes.setAllItemsSelected(value)),
        clearSelected: (value) => dispatch(actionTypes.clearSelected()),

    }
};


export default connect(mapStateToProps, mapDispatchToProps)(ItemsList);


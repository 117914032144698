import React, {useRef, useEffect, Fragment, useState} from 'react';
import ItemsList from './ItemsList/ItemsList'
import SearchCockpit from './SearchCockpit/SearchCockpit'
import AccountSelector from './AccountSelector/AccountSelector'
import ReplicationConfirmation from './ReplicationConfirmation/ReplicationConfirmation'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import {connect} from "react-redux";
import * as actionTypes from "../../../store/actions";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";

const ItemReplicator = (props) => {
    const itemSelectorAccordionRef = useRef(null);

    const clickHandler = (e)=>{
      e.target.blur()
    };

    useEffect(()=>{
        props.setReplicationJobId(null);
    },[]);

    const errorAlert = () => {
        if (props.destinationAccounts.length === 0 && props.loadedAccounts) {
            return (
                <Row>
                    <Col md={{span: 6, offset: 3}} className="my-2">
                        <Alert variant='danger'>
                            É necessário autorizar pelo menos uma Mercadolivre em "Contas"
                        </Alert>
                    </Col>
                </Row>
            )
        }
    };

    return (
        <Fragment>
            {errorAlert()}
            <h4>Replicador de Anúncios</h4>
            <Accordion defaultActiveKey="0">
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} onClick={clickHandler} variant="link" eventKey="0">
                            1. Selecione a Conta de Destino ({props.destinationSellerName})
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <AccountSelector/>
                    </Accordion.Collapse>
                </Card>
                <Card ref={itemSelectorAccordionRef}>
                    <Card.Header>
                        <Accordion.Toggle as={Button} onClick={clickHandler} variant="link" eventKey="1">
                            2. Selecione os Anúncios ({props.selectedItems.length})
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <div className="p-2">
                            <SearchCockpit/>
                            <ItemsList newPageScrollToRef={itemSelectorAccordionRef}/>
                        </div>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} onClick={clickHandler} variant="link" eventKey="2">
                            3. Confirmação
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                        <ReplicationConfirmation/>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </Fragment>
    );
};

const mapStateToProps = state => {
    return {
        destinationAccounts: state.replication.destinationAccounts,
        destinationSellerName: state.replication.destinationSellerName,
        loadedAccounts: state.replication.loadedAccounts,
        selectedItems: state.item.selectedItems,
    }
};


const mapDispatchToProps = dispatch => {
    return {
        setReplicationJobId: (value) => dispatch(actionTypes.setReplicationJobId(value)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemReplicator);

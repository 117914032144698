import React, {useEffect, Fragment, useState} from "react";
import {connect} from "react-redux";
import * as actionTypes from "../../../../store/actions";
import Form from "react-bootstrap/Form";

const AccountSelector = (props) => {
    const handleAccountChange = (e) => {
        props.setDestinationSellerId(e.target.value, null);
        props.clearList();
        props.clearSelected();
        props.setNickname('');
        props.setTitleFilter('');
        e.target.blur();
    };

    return (
        <Fragment>
            <Form inline>
                <Form.Control
                    as="select"
                    className="m-sm-2"
                    id="sellerSelector"
                    onChange={handleAccountChange}
                >
                    {
                        props.destinationAccounts.map(
                            (account) => {
                                return (<option key={account.mlSellerId} value={account.mlSellerId}>{account.nickname}</option>)
                            }
                        )
                    }
                </Form.Control>
            </Form>
            </Fragment>
    );
};

const mapStateToProps = state => {
    return {
        destinationAccounts: state.replication.destinationAccounts,
        destinationSellerId: state.replication.destinationSellerId,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setDestinationSellerId: (value) => dispatch(actionTypes.setDestinationSellerId(value)),
        clearList: () => dispatch(actionTypes.clearList()),
        clearSelected: () => dispatch(actionTypes.clearSelected()),
        setNickname: (value) => dispatch(actionTypes.setNickname(value)),
        setTitleFilter: (value) => dispatch(actionTypes.setTitleFilter(value)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSelector);
import * as actionTypes from '../actions/actionsTypes'
import {updateObject} from './../utility'

const initialState = {
    destinationSellerId: null,
    destinationSellerName: '',
    destinationAccounts: [],
    replicationJobId:null,
    failedJobCreation:false,
    loadedAccounts:false
};

const replicationReducer = (state = initialState, action) => {
    const setDestinantionSellerId = (state, action) => {
        let destinationSellerName;
        if (action.payload.nickname !== null){
            destinationSellerName = action.payload.nickname;
        }else{
            destinationSellerName = state.destinationAccounts.find(o => o.mlSellerId === parseInt(action.payload.mlSellerId)).nickname;
        }
        return updateObject(state, {
            destinationSellerId: parseInt(action.payload.mlSellerId),
            destinationSellerName: destinationSellerName
        });
    };

    switch (action.type) {
        case actionTypes.SET_DESTINATION_SELLER_ID :
            return setDestinantionSellerId(state, action);
        case actionTypes.UPDATE_REPLICATION_STATE:
            return updateObject(state, {...action.payload});
        case actionTypes.CLEAR_STATE :
            return initialState;
        default:
            return state;
    }
};

export default replicationReducer;
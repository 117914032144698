import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import MlAccount from './MlAccount/MlAccount'
import MlAuthorization from './MlAuthorization/MlAuthorization'
import BlockUser from './BlockUser/BlockUser'
import PriceSimulation from './PriceSimulation/PriceSimulation'
import Home from './Home/Home'
import ItemReplicator from './ItemReplicator/ItemReplicator'
import ItemReplicatorResult from './ItemReplicatorResult/ItemReplicatorResult'
import Question from './Question/Question'
import {Route, useHistory} from "react-router-dom";
import {NavLink} from 'react-router-dom'
import React, {useEffect} from "react";
import {logout} from './../../services/auth';
import * as actionTypes from "../../store/actions";
import {connect} from "react-redux";
import {Container} from "@mui/material";

const Dashboard = (props) => {
    useEffect(()=>{
        props.initAccounts();
    },[]);


    const history = useHistory();

    const handleLogout = () => {
        logout();
        props.clearState();
        history.push('/');
    };

    return (
        <Container>
            <Navbar bg="dark" variant="dark" expand="lg">
                <Navbar.Brand as={NavLink} to="/app">Mercadodata</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link as={NavLink} to="/app/question">Perguntas</Nav.Link>
                    <Nav.Link as={NavLink} to="/app/simulation">Simulador</Nav.Link>
                    <Nav.Link as={NavLink} to="/app/block">Bloqueio</Nav.Link>
                    <Nav.Link as={NavLink} to="/app/replication">Replicador</Nav.Link>
                    <Nav.Link as={NavLink} to="/app/account">Contas</Nav.Link>
                </Nav>
                <Nav>
                    <NavDropdown title="Perfil" id="collasible-nav-dropdown" variant="dark">
                        <NavDropdown.Item onClick={handleLogout}>Sair</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
                </Navbar.Collapse>
            </Navbar>
            <div className='my-4 mx-1'>
                <Route path="/app" exact component={Home}/>
                <Route path="/app/account" exact component={MlAccount}/>
                <Route path="/app/replication" exact component={ItemReplicator}/>
                <Route path="/app/replication/result" exact component={ItemReplicatorResult}/>
                <Route path="/app/authorization" component={MlAuthorization}/>
                <Route path="/app/block" component={BlockUser}/>
                <Route path="/app/simulation" component={PriceSimulation}/>
                <Route path="/app/question" component={Question}/>
            </div>
        </Container>
    );
};


const mapDispatchToProps = dispatch => {
    return {
        clearState: () => dispatch(actionTypes.clearState()),
        initAccounts: () => dispatch(actionTypes.initAccounts()),
    }
};


export default connect(null, mapDispatchToProps)(Dashboard);
import * as actionTypes from './actionsTypes';
import {api} from './../../services/api'

export const updateState = (payload) => {
    return {
        type: actionTypes.UPDATE_USER_STATE,
        payload
    }
};

export const updateBlacklist = (mlSellerId) => {
    return (dispatch) => {
        dispatch(updateState({blacklist: []}))
        api.get(`ml/orderBlacklist/${mlSellerId}`).then((response) => {
            dispatch(updateState({blacklist: response.data}));
        });
    }
};

export const blockUser = (mlSellerId, mlNicknameToBlock) => {
    return (dispatch, getState) => {
        const state = getState();

        api.post('ml/orderBlacklist', {mlSellerId, mlNicknameToBlock}).then((response) => {
            dispatch(updateState({
                blacklistOperationSuccess: true,
                blacklistOperationError: 'Usuário bloqueado com sucesso'
            }));

            if (!(state.user.blacklist.some(item => item.id === response.data.blockedUserId))) {
                dispatch(updateState({
                    blacklist: state.user.blacklist.concat({
                        id: response.data.blockedUserId,
                        nickname: mlNicknameToBlock
                    })
                }));
            }
        }).catch(e => {
            dispatch(updateState({
                blacklistOperationSuccess: false,
                blacklistOperationError: 'Não foi possível bloquear o usuário'
            }));
        });
    }
};
export const unblockUser = (mlSellerId, mlBlockedUserId) => {
    return (dispatch, getState) => {
        const state = getState();
        api.delete(`ml/orderBlacklist/${mlSellerId}/${mlBlockedUserId}`).then((response) => {
            dispatch(updateState({
                blacklistOperationSuccess: true,
                blacklistOperationError: 'Usuário desbloqueado com sucesso'
            }));


            dispatch(updateState({
                blacklist: state.user.blacklist.filter(item => item.id !== mlBlockedUserId)
            }));

        }).catch(e => {
            dispatch(updateState({
                blacklistOperationSuccess: false,
                blacklistOperationError: 'Não foi possível desbloquear o usuário'
            }));
        });
    }
};

export const updateBalance = () => {
    return (dispatch) => {
        api.get('user/balance').then((response) => {
            dispatch(updateState(response.data));
        });
    }
};
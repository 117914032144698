import React, {useState, useEffect, Fragment} from 'react';
import {connect} from "react-redux";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Alert from "react-bootstrap/Alert";
import * as actionTypes from "../../../store/actions";
import {api} from './../../../services/api'
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import {MdCreditCard} from "react-icons/md";


const MlAccount = (props) => {

    useEffect(() => {
        props.updateBalance()
    }, []);


    const [message, setMessage] = useState({text:null, isError:false});
    const [voucher, setVoucher] = useState('');
    const [searchButtonDisabled, setSearchButtonDisabled] = useState(true);
    const [loading, setLoading] = useState(false);

    const alert = () => {
        if (message.text  !== null) {
            return (
                <Alert variant={message.isError ? 'danger' : 'success'}>{message.text}</Alert>
            )
        }
    };

    const zeroPad = (num, places) => String(num).padStart(places, '0');

    const formatDate = (date) => {
        date = new Date(date);
        return zeroPad(date.getDate(), 2) + "/" + zeroPad(parseInt(date.getMonth() + 1), 2) + "/" + date.getFullYear();
    };

    const handleRedeem = (e) => {
        setSearchButtonDisabled(true);
        setLoading(true);
        api.post('voucher/redeem', {code: voucher}).then((response) => {
            props.updateBalance();
            setMessage({isError:false, text:`${response.data.results.quantity} créditos resgatados com sucesso`});
            setLoading(false);
        }).catch(
            (e)=>{
                setMessage({isError:true, text:e.response.data.message});
                setLoading(false);
            }
        );
    };

    return (
        <Fragment>
            <Row>
                <Col md={{span: 6, offset: 3}} className="my-2">
                    <Card>
                        <Card.Body>
                            <Card.Title><MdCreditCard/> Créditos Disponíveis: {props.creditsExpired ? 0 : props.credits}</Card.Title>
                            <Card.Text>
                                Data de
                                Expiração: {props.expirationDate === null ? '-' : formatDate(props.expirationDate)}
                            </Card.Text>
                            <InputGroup className="mb-3">
                                <FormControl onChange={(e) => {
                                    setVoucher(e.target.value);
                                    setSearchButtonDisabled(false)
                                }}
                                             disabled={loading}
                                             placeholder="Voucher"
                                             aria-label="Voucher"
                                             aria-describedby="basic-addon2"
                                />
                                <InputGroup.Append>
                                    <Button disabled={searchButtonDisabled} onClick={handleRedeem}
                                            variant="outline-secondary">Resgatar</Button>
                                </InputGroup.Append>
                                {
                                    loading ? <Spinner animation="border"/> : null
                                }
                            </InputGroup>
                            {alert()}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

const mapStateToProps = state => {
    return {
        credits: state.user.credits,
        expirationDate: state.user.expirationDate,
        creditsExpired: state.user.creditsExpired,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        updateBalance: () => dispatch(actionTypes.updateBalance()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MlAccount);

import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import {isAuthenticated} from "./services/auth";
import Dashboard from './containers/Dashboard/Dashboard'
import SignIn from './containers/SignIn/SignIn'
import React from "react";

const PrivateRoute = ({component: Component, ...rest}) => (
    <Route
        {...rest}
        render={
            props =>
                isAuthenticated() ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{pathname: "/", state: {from: props.location}}}/>
                )
        }
    />
);

const HomeToAppRedirect = ({component: Component, ...rest}) => (
    <Route
        {...rest}
        render={
            props =>
                isAuthenticated() ? (
                    <Redirect to={{pathname: "/app/question", state: {from: props.location}}}/>
                ) : (
                    <Component {...props} />
                )
        }
    />
);

const Routes = () =>
    (
        <BrowserRouter>
            <Switch>
                <HomeToAppRedirect exact path="/" component={SignIn}/>
                <PrivateRoute path="/app" component={Dashboard}/>
                <Route path="*" component={() => <h1>Page not found</h1>}/>
            </Switch>
        </BrowserRouter>
    )
;

export default Routes;
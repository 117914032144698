import * as actionTypes from './actionsTypes';

export const setSort = (value) => {
    return {
        type: actionTypes.SET_SORT,
        payload: {value}
    }
};

export const setNickname = (value) => {
    return {
        type: actionTypes.SET_NICKNAME,
        payload: {value}
    }
};

export const setLimit = (value) => {
    return {
        type: actionTypes.SET_LIMIT,
        payload: {value}
    }
};

export const setSearching = (value) => {
    return {
        type: actionTypes.SET_SEARCHING,
        payload: {value}
    }
};

export const setOffset = (value) => {
    return {
        type: actionTypes.SET_OFFSET,
        payload: {value}
    }
};

export const setTitleFilter = (value) => {
    return {
        type: actionTypes.SET_TITLE_FILTER,
        payload: {value}
    }
};


export const setSearchBoxDisabled = (value) => {
    return {
        type: actionTypes.SET_SEARCH_BOX_DISABLED,
        payload: {value}
    }
};

import {useState} from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import {useHistory} from "react-router-dom";
import {NavLink} from 'react-router-dom';
import {login} from './../../services/auth';
import {api} from './../../services/api';
import React from "react";
import {connect} from "react-redux";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import {BsPersonPlusFill} from "react-icons/bs"


const SignIn = (props) => {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const initalFormState={firstName: '', lastName: '', email: '', password: ''};
    const [registerForm, setRegisterForm] = useState(initalFormState);

    const handleLogin = async (e) => {
        e.preventDefault();
        if (!email || !password) {
            setErrorMessage("Preencha e-mail e senha para continuar!");
        } else {
            try {
                const response = await api.post("/user/login", {email, password});
                login(response.data.token);
                setErrorMessage(null);
                setEmail(null);
                setPassword(null);
                history.push('/app/question');
            } catch (err) {
                setErrorMessage("Houve um problema com o login, verifique suas credenciais.");
            }
        }
    };

    const handleSignup = async (e) => {
        e.preventDefault();
        if (registerForm.firstName.length < 1 || registerForm.lastName.length < 1 || registerForm.email.length < 1 || registerForm.password.length < 1) {
            setErrorMessage("Todos os campos são obrigatórios!");
        } else {
            try {
                const response = await api.post("/user/new", registerForm);
                login(response.data.token);
                setErrorMessage(null);
                setRegisterForm(initalFormState);
                history.push('/app');
            } catch (err) {
                setErrorMessage("Houve um problema com o login, verifique suas credenciais.");
            }
        }
    };

    const errorAlert = () => {
        if (errorMessage !== null) {
            return (
                <Row>
                    <Col md={{span: 6, offset: 3}} className="my-2">
                        <Alert variant='danger'>
                            {errorMessage}
                        </Alert>
                    </Col>
                </Row>
            )
        }
    };

    return (
        <Container>
            <Navbar bg="dark" variant="dark">
                <Navbar.Brand className="mr-auto" as={NavLink} to="/">Mercadodata</Navbar.Brand>

                <Form inline>
                    <Form.Control className="mr-sm-2" type="email" placeholder="Email"
                                  onChange={e => setEmail(e.target.value)} value={email}/>
                    <Form.Control className="mr-sm-2" type="password" placeholder="Senha"
                                  value={password} onChange={e => setPassword(e.target.value)}/>
                    <Button variant="outline-info" onClick={handleLogin}>
                        Entrar
                    </Button>
                </Form>
            </Navbar>
            {errorAlert()}
            <Row>
                <Col md={{span: 6, offset: 3}} className="my-2">
                    <Card>
                        <Card.Body>
                            <Card.Title><BsPersonPlusFill/> Cadastro</Card.Title>
                            <Form>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control type="text" placeholder="Nome" value={registerForm.firstName}
                                                  onChange={(e) => {
                                                      setRegisterForm({...registerForm, firstName: e.target.value})
                                                  }}/>
                                </Form.Group>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Sobrenome</Form.Label>
                                    <Form.Control type="text" placeholder="Sobrenome" value={registerForm.lastName}
                                                  onChange={(e) => {
                                                      setRegisterForm({...registerForm, lastName: e.target.value})
                                                  }}/>
                                </Form.Group>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Email" value={registerForm.email}
                                                  onChange={(e) => {
                                                      setRegisterForm({...registerForm, email: e.target.value})
                                                  }}/>
                                </Form.Group>
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Senha</Form.Label>
                                    <Form.Control type="password" placeholder="Senha" value={registerForm.password}
                                                  onChange={(e) => {
                                                      setRegisterForm({...registerForm, password: e.target.value})
                                                  }}/>
                                </Form.Group>

                                <Button onClick={handleSignup} variant="primary" type="submit">
                                    Cadastrar
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </Container>
    );
};

const mapStateToProps = state => {
    return {
        selectedItems: state.item.selectedItems,
        fetchItemsFailed: state.item.fetchItemsFailed,
        listItems: state.item.listItems,
        pagingEnd: state.item.pagingEnd,
        pagingStart: state.item.pagingStart,
        pagingTotal: state.item.pagingTotal,
        nickname: state.search.nickname,
        offset: state.search.offset,
        searching: state.search.searching,
        limit: state.search.limit,
        sort: state.search.sort,
        searchButtonDisabled: state.search.searchButtonDisabled,
        titleFilter: state.search.titleFilter,
        destinationSellerId: state.replication.destinationSellerId,
    }
};

export default connect(mapStateToProps)(SignIn);
import React, {useState, useEffect, Fragment} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";
import {Autocomplete, Stack, TextField, Button, Avatar, Grid, Container, Typography, FormControl } from "@mui/material";
import {getLatestQuestions, submitAnswer, updateResponse, reset} from "../../../slices/questionSlice";

const renderQuestion = (question, dispatch, response, full_answer) => {
    const submitHandler = (questionId, answer, sellerId) => {
        dispatch(submitAnswer({questionId, answer, sellerId}))
    };

    const updateResponseHandler = (entity, event, value) => {
        dispatch(updateResponse({[entity]: value}))
    };
return (
    <Stack spacing={2} pb={5} key={question.id}>
        <Grid container spacing={1} mb={1}>
            <Grid item>
                <Avatar src={question.image} variant="square" sx={{ width: 100, height: 100 }} />
            </Grid>
            <Grid alignItems="left" item xs={8} >
                <Typography gutterBottom variant="h5" component="div">
                    {question.question}
                </Typography>
                <Typography gutterBottom variant="subtitle1" component="div">
                    {question.item}
                </Typography>
            </Grid>
        </Grid>
        <Autocomplete
            disablePortal
            id="salut_text"
            options={question.salut}
            onInputChange={(event, value) => updateResponseHandler("salut", event, value)}
            defaultValue={question.salut[0]}
            renderInput={(params) => <TextField fullWidth {...params} label="Saudação" />}
        />
        <Autocomplete
            disablePortal
            id="answer_text"
            freeSolo
            onInputChange={(event, value) => updateResponseHandler("answer", event, value)}
            options={question.answer}
            fullWidth
            renderInput={(params) => <TextField multiline helperText={response.answer.length+'/2000 Caracteres'} {...params} rows={4}  label="Resposta"/>}
        />

        <Autocomplete
            disablePortal
            id="goodbye_text"
            onInputChange={(event, value) => updateResponseHandler("goodbye", event, value)}
            defaultValue={question.goodbye[0]}
            options={question.goodbye}
            renderInput={(params) => <TextField fullWidth {...params} label="Despedida" />}
        />
        <Button onClick={e => submitHandler(question.id, full_answer, question.sellerId)} variant="contained">Responder</Button>
    </Stack>
)
}
export default function Question() {
    const dispatch = useDispatch()
    const questions = useSelector((state) => state.question.entities)
    const response = useSelector((state) => state.question.response)
    const loading = useSelector((state) => state.question.loading)
    const error = useSelector((state) => state.question.error)
    const full_answer = useSelector((state) => state.question.response.full_answer)

    useEffect(() => {
        dispatch(getLatestQuestions())
    }, [])

        return (
            <Container>
                {error ? <Typography>Failed Loading</Typography> : null}
                {loading ? <Typography>Carregando...</Typography> : null}
                {questions.length === 0 ? <Typography>Nenhuma pergunta encontrada</Typography> : null}
                {questions.map(element => renderQuestion(element, dispatch, response, full_answer))}
            </Container>
        );
    }
;

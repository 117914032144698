import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import {connect} from "react-redux";
import * as actionTypes from "../../../../store/actions";
import Form from "react-bootstrap/Form";
import {BsSearch} from 'react-icons/bs';
import {FaFilter} from 'react-icons/fa';


const SearchCockpit = (props) => {

    const handleChange = (e) => {
        props.setSearchBoxDisabled(false);
        props.setOffset(0);
        props.setNickname(e.target.value);
    };

    const handleTitleChange = (e) => {
        props.setSearchBoxDisabled(false);
        props.setOffset(0);
        props.setTitleFilter(e.target.value);
    };

    const handleSearch = (e) => {
        props.setSearchBoxDisabled(true);
        props.initItems();
    };

    const handleSortChange = (e) => {
        props.setSort(e.target.value);
        if (props.pagingTotal > 0) {
            props.initItems();
        }
    };

    const handleLimitChange = (e) => {
        props.setLimit(e.target.value);
        if (props.pagingTotal > 0) {
            props.initItems();
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (!props.searchButtonDisabled) {
                props.setSearchBoxDisabled(true);
                props.initItems(e.target.value);
            }
            e.target.blur();
            e.preventDefault();
        }
    };

    const handleTitleFilterKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (!props.searchButtonDisabled) {
                props.setSearchBoxDisabled(true);
                props.initItems();
            }
            e.target.blur();
            e.preventDefault();
        }
    };
    const handleFocus = (e) => {
        e.target.select();
    };
    return (
        <div className="mb-1">
            <Form inline>
                <InputGroup className="my-1 mr-sm-2">
                    <Form.Label className="my-1 mr-2" htmlFor="limitSelector">
                        Vendedor
                    </Form.Label>
                    <FormControl
                        placeholder="Nome do Vendedor"
                        aria-label="Nome do Vendedor"
                        aria-describedby="basic-addon2"
                        value={props.nickname.toUpperCase()}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        onFocus={handleFocus}
                    />
                    <InputGroup.Append>
                        <Button
                            disabled={props.searchButtonDisabled}
                            onClick={handleSearch}
                            variant="outline-primary"><BsSearch/></Button>
                    </InputGroup.Append>
                </InputGroup>
                <Form.Label className="my-1 mr-sm-2" htmlFor="sortSelector">
                    Ordenar
                </Form.Label>
                <Form.Control
                    as="select"
                    className="my-1 mr-sm-2"
                    id="sortSelector"
                    onChange={handleSortChange}
                >
                    <option value="relevance">Relevância</option>
                    <option value="price_desc">Maior Preço</option>
                    <option value="price_asc">Menor Preço</option>
                </Form.Control>
                <Form.Label className="my-1 mr-2" htmlFor="limitSelector">
                    Exibir
                </Form.Label>
                <Form.Control
                    as="select"
                    className="my-1 mr-sm-2"
                    id="limitSelector"
                    onChange={handleLimitChange}
                >
                    <option value="10">10</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                </Form.Control>
                {
                    props.searching ? <Spinner animation="border"/> : null
                }
            </Form>
            <Form inline>
                <Form.Label className="my-1 mr-2" htmlFor="limitSelector">
                    Título
                </Form.Label>
                <InputGroup className="my-1 mr-sm-4">
                    <FormControl
                        placeholder="Título do Anúncio"
                        aria-label="Título do Anúncio"
                        aria-describedby="basic-addon2"
                        value={props.titleFilter}
                        onChange={handleTitleChange}
                        onKeyDown={handleTitleFilterKeyDown}
                        onFocus={handleFocus}
                    />
                    <InputGroup.Append>
                        <Button
                            disabled={props.searchButtonDisabled}
                            onClick={handleSearch}
                            variant="outline-primary"><FaFilter/></Button>
                    </InputGroup.Append>
                </InputGroup>
            </Form>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        selectedItems: state.item.selectedItems,
        fetchItemsFailed: state.item.fetchItemsFailed,
        listItems: state.item.listItems,
        pagingEnd: state.item.pagingEnd,
        pagingStart: state.item.pagingStart,
        pagingTotal: state.item.pagingTotal,
        nickname: state.search.nickname,
        offset: state.search.offset,
        searching: state.search.searching,
        limit: state.search.limit,
        sort: state.search.sort,
        searchButtonDisabled: state.search.searchButtonDisabled,
        titleFilter: state.search.titleFilter,
        destinationSellerId: state.replication.destinationSellerId,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        initItems: (value) => dispatch(actionTypes.initItems(value)),
        setOffset: (value) => dispatch(actionTypes.setOffset(value)),
        setSort: (value) => dispatch(actionTypes.setSort(value)),
        setLimit: (value) => dispatch(actionTypes.setLimit(value)),
        setNickname: (value) => dispatch(actionTypes.setNickname(value)),
        setSearchBoxDisabled: (value) => dispatch(actionTypes.setSearchBoxDisabled(value)),
        setTitleFilter: (value) => dispatch(actionTypes.setTitleFilter(value)),

    }
};


export default connect(mapStateToProps, mapDispatchToProps)(SearchCockpit);
import * as actionTypes from '../actions/actionsTypes'
import {updateObject} from './../utility'

const initialState = {
    selectedItems: [],
    sellerId: null,
    listItems: [],
    fetchItemsFailed: false,
    pagingEnd: 0,
    pagingStart: 0,
    pagingTotal: 0,
    searching: false,
    allItemsSelected: false,
    sameSellerListingNotAllowedError: false,
};

const deleteItem = (state, action) => {
    const updatedArray = state.selectedItems.filter((result, index) => {
            return !action.payload.items.includes(result)
        }
    );
    return updateObject(state, {selectedItems: updatedArray, allItemsSelected: false})
};

const setItems = (state, action) => {
    return updateObject(state, {
        listItems: action.payload.items,
        fetchItemsFailed: false,
        sameSellerListingNotAllowedError: false,
        pagingStart: action.payload.paging.start,
        pagingEnd: action.payload.paging.end,
        pagingTotal: action.payload.paging.total,
        sellerId: action.payload.sellerId
    });
};

const computeAllItemsSelected = (state, action) => {
    const pageSelectedCount = state.listItems.reduce((a, b) => {
        return a + (state.selectedItems.includes(b.id) ? 1 : 0)
    }, 0);
    return updateObject(state, {allItemsSelected: pageSelectedCount === state.listItems.length});
};

const itemReducer = (state = initialState, action) => {
    switch (action.type) {
        case  actionTypes.ADD_ITEMS :
            return updateObject(state, {selectedItems: state.selectedItems.concat(action.payload.items)});
        case actionTypes.DELETE_ITEMS :
            return deleteItem(state, action);
        case actionTypes.CLEAR_LIST :
            return updateObject(state, {listItems: [], pagingEnd:0, pagingStart:0, pagingTotal:0, sellerId: null,  sameSellerListingNotAllowedError: false});
        case actionTypes.SET_ITEMS :
            return setItems(state, action);
        case actionTypes.FETCH_ITEMS_FAILED :
            return updateObject(state, {fetchItemsFailed: true});
        case actionTypes.SET_ALL_ITEMS_SELECTED :
            return updateObject(state, {allItemsSelected: action.payload.value});
        case actionTypes.COMPUTE_ALL_ITEMS_SELECTED :
            return computeAllItemsSelected(state, action);
        case actionTypes.SET_SAME_SELLER_LISTING_NOT_ALLOWED_ERROR :
            return updateObject(state, {sameSellerListingNotAllowedError: action.payload.value});
        case actionTypes.UPDATE_ITEM_STATE:
            return updateObject(state, {...action.payload});
        case actionTypes.CLEAR_STATE :
            return initialState;
        default:
            return state;
    }

};

export default itemReducer;
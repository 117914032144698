import * as actionTypes from '../actions/actionsTypes'
import {updateObject} from './../utility'

const initialState = {
    credits: 0,
    expirationDate: null,
    creditsExpired: false,
    blacklist:[],
    blacklistOperationSuccess:null,
    blacklistOperationError:null
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_USER_STATE :
            return updateObject(state, {
                ...action.payload
            });
        case actionTypes.CLEAR_STATE :
            return initialState;
        default:
            return state;
    }
};

export default userReducer;
import * as actionTypes from './actionsTypes';
import * as actions from './index';
import {api} from './../../services/api'

export const addItems = (items) => {
    return {
        type: actionTypes.ADD_ITEMS,
        payload: {
            items
        }
    }
};

export const deleteItems = (items) => {
    return {
        type: actionTypes.DELETE_ITEMS,
        payload: {
            items
        }
    }
};

export const setItems = (response) => {
    return {
        type: actionTypes.SET_ITEMS,
        payload: {
            items: response.results,
            paging: response.paging,
            sellerId: response.sellerId
        }
    }
};

export const fetchItemsFailed = () => {
    return {
        type: actionTypes.FETCH_ITEMS_FAILED
    }
};

export const setAllItemsSelected = (value) => {
    return {
        type: actionTypes.SET_ALL_ITEMS_SELECTED,
        payload: {
            value
        }
    }
};

export const clearList = () => {
    return {
        type: actionTypes.CLEAR_LIST
    }
};

export const clearSelected = () => {
    return {
        type: actionTypes.UPDATE_ITEM_STATE,
        payload: {
            selectedItems: [],
        }
    }
};

export const computeAllItemsSelected = () => {
    return {
        type: actionTypes.COMPUTE_ALL_ITEMS_SELECTED
    }
};

export const setSameSellerListingNotAllowedError = (value) => {
    return {
        type: actionTypes.SET_SAME_SELLER_LISTING_NOT_ALLOWED_ERROR,
        payload: {
            value
        }
    }
};

export const initItems = (inputNickname = null) => {
    return (dispatch, getState) => {
        dispatch(actions.setSearching(true));
        const state = getState();

        const {nickname, offset, limit, sort, titleFilter} = state.search;
        const {selectedItems} = state.item;
        const {destinationSellerId} = state.replication;

        const finalNickname = inputNickname === null ? nickname : inputNickname;
        api.get(`/ml/getItems?nickname=${finalNickname}&offset=${offset}&limit=${limit}&sort=${sort}&q=${titleFilter}`)
            .then(
                (response) => {
                    dispatch(actions.setSearching(false));
                    if (response.data.sellerId === destinationSellerId) {
                        dispatch(clearList());
                        dispatch(setSameSellerListingNotAllowedError(true));
                    } else {
                        const pageSelectedCount = response.data.results.reduce((a, b) => {
                            return a + (selectedItems.includes(b.id) ? 1 : 0)
                        }, 0);
                        dispatch(setItems(response.data));
                        dispatch(setAllItemsSelected(pageSelectedCount === response.data.results.length && response.data.results.length > 0));
                    }
                })
            .catch(error => {
                dispatch(fetchItemsFailed())
            })
    }
};
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import App from './containers/App/App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import itemReducer from './store/reducers/itemReducer';
import searchReducer from './store/reducers/searchReducer';
import replicationReducer from "./store/reducers/replicationReducer";
import userReducer from "./store/reducers/userReducer";
import HttpsRedirect from 'react-https-redirect';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { configureStore } from '@reduxjs/toolkit'


import authenticationSlice from './slices/authenticationSlice'
import questionSlice from './slices/questionSlice'

const store = configureStore({
    reducer: {
        item: itemReducer,
        search: searchReducer,
        replication: replicationReducer,
        user: userReducer,
        authentication: authenticationSlice,
        question: questionSlice
    }
})

ReactDOM.render(
    <Provider store={store}><HttpsRedirect><App/></HttpsRedirect></Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

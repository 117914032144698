import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {api} from '../services/api'

const initialState = {
    entities : [],
    response:{
        answer: "",
        goodbye: "",
        salut: "",
        full_answer: "",
    },
    loading: false,
    error: false,
    errorMessage: ""
}

export const getLatestQuestions = createAsyncThunk(
    'users/getLatestQuestion',
    async () => {
            const response = await api.get('/ml/questions')
            return response.data
    }
)


export const submitAnswer = createAsyncThunk(
    'users/submitAnswer',
    async ({questionId, answer, sellerId}, thunkAPI) => {
        const response = await api.put(`/ml/questions/${questionId}`, {answer, sellerId})
        thunkAPI.dispatch(reset())
        thunkAPI.dispatch(getLatestQuestions())
        console.log(response.data)
        return response.data
    }
)


export const questionSlice = createSlice({
    name: 'question',
    initialState,
    reducers: {
        reset: () => initialState,
        updateResponse: (state, action) => {
            state.response = {
                ...state.response,
                ...action.payload
            }
            state.response.full_answer = `${state.response.salut} ${state.response.answer} ${state.response.goodbye}`
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getLatestQuestions.fulfilled, (state, action) => {
            state.entities = action.payload.results
            state.error = false
            state.loading = false
        })
        builder.addCase(getLatestQuestions.pending, (state, action) => {
            state.loading = true
            state.error = false
        })
        builder.addCase(getLatestQuestions.rejected, (state, action) => {
            state.loading = false
            state.error = true
        })
        builder.addCase(submitAnswer.fulfilled, (state, action) => {
        })
        builder.addCase(submitAnswer.rejected, (state, action) => {
        })
    },
})

// Action creators are generated for each case reducer function
export const {reset, updateResponse} = questionSlice.actions

export default questionSlice.reducer
import React, {useState, useEffect, Fragment} from 'react';
import {connect} from "react-redux";
import {getToken} from './../../../services/auth'
import {baseUrl} from './../../../services/api'
import EventSource from 'eventsource'
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import {MdCloudDone, MdError} from 'react-icons/md';
import Spinner from 'react-bootstrap/Spinner'
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import * as actionTypes from "../../../store/actions";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import {mlPublicApi} from "../../../services/api"

const PriceSimulation = (props) => {
        const [account, setAccount] = useState(null);
        const [taxPercentage, setTaxPercentage] = useState(0.04);
        const [itemData, setItemData] = useState(null);
        const [itemCost, setItemCost] = useState(0);
        const [buttonDisabled, setbuttonDisabled] = useState(false);
        const [shippingData, setShippingData] = useState(null);
        const [listingData, setListingData] = useState(null);
        const [categoryData, setCategoryData] = useState(null);
        const [lastSearchedAccount, setLastSearchedAccount] = useState(null);
        const [mlItemLink, setMlItemLink] = useState('');
        const [table, setTable] = useState([]);

        const formatCurrency = (value, fractionDigits=2) => {
            return parseFloat(value.toFixed(fractionDigits))
        };

        useEffect(() => {
            //Clássico e Premium
            if (itemData === null || shippingData === null || listingData === null) {
                return
            }
            //Clássico / Premium

            const t = [];
            let custoFrete;
            const preco = itemData.price;
            if (itemData.shipping['free_shipping']){
                custoFrete = shippingData.coverage['all_country']['list_cost'];
            }else{
                custoFrete = 0
            }

            const tarifaAnuncio = {
                classico: listingData.find(o => o.listing_type_id === 'gold_special').sale_fee_amount,
                premium: listingData.find(o => o.listing_type_id === 'gold_pro').sale_fee_amount
            };

            const totalMercadoPago = {
                classico: preco - tarifaAnuncio.classico - custoFrete,
                premium: preco - tarifaAnuncio.premium - custoFrete
            };

            const impostoSimplesNacional = preco * taxPercentage;

            const lucro = {
                classico: totalMercadoPago.classico - impostoSimplesNacional - itemCost,
                premium: totalMercadoPago.premium - impostoSimplesNacional - itemCost
            };

            const margin = {
                classico: lucro.classico/itemCost ,
                premium: lucro.premium/itemCost
            };


            t.push(['Preço', preco, preco]);
            t.push(['Tarifa Anúncio', tarifaAnuncio.classico, tarifaAnuncio.premium]);
            t.push(['Custo Frete', custoFrete, custoFrete]);

            t.push([<b>Total
                Mercadopago</b>, formatCurrency(totalMercadoPago.classico), formatCurrency(totalMercadoPago.premium)]);

            t.push(['Imposto Simples', formatCurrency(impostoSimplesNacional), formatCurrency(impostoSimplesNacional)]);
            t.push(['Custo Mercadoria', itemCost, itemCost]);

            t.push([<b>Lucro</b>, <b>{formatCurrency(lucro.classico)}</b>,
                <b>{formatCurrency(lucro.premium)}</b>]);

            if (itemCost > 0) {
                t.push(['Margem', `${formatCurrency(margin.classico*100)} %`, `${formatCurrency(margin.premium*100)} %`]);
            }else{
                t.push(['Margem', '-', '-']);
            }


            setTable(t)
        }, [itemData, shippingData, listingData, taxPercentage, itemCost]);

        useEffect(() => {
            if (props.destinationAccounts.length > 0 && account === null) {
                const accountId = props.destinationAccounts[0].mlSellerId;
                setAccount(accountId);
            }
        }, [props.destinationAccounts]);

        const renderRow = (row) => {
            return (
                <tr key={row[0]}>
                    <td>{row[0]}</td>
                    <td>{row[1]}</td>
                    <td>{row[2]}</td>
                </tr>
            )
        };

        const alert = () => {
            if (props.blacklistOperationSuccess !== null) {
                return (<Alert
                    variant={props.blacklistOperationSuccess ? 'success' : 'danger'}>{props.blacklistOperationError}</Alert>)
            }
        };


        const cancelSubmit = (e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
            }
        };

        const handleSearch = async () => {
            const re = new RegExp('MLB[-]?([0-9]*)');
            const r = mlItemLink.match(re);
            if (!r) {
                return
            }
            const mlItemId = 'MLB' + mlItemLink.match(re)[1];
            setbuttonDisabled(true);
            const item = await mlPublicApi.get(`items/${mlItemId}`);
            setItemData(item.data);
            const category = await mlPublicApi.get(`categories/${item.data['category_id']}/shipping_preferences`);
            setCategoryData(category.data);
            // const shipping = await mlPublicApi.get(`users/${account}/shipping_options/free?currency_id=BRL&listing_type_id=${item.data['listing_type_id']}&condition=${item.data['condition']}&category_id=${item.data['category_id']}&item_price=${item.data['price']}&verbose=true&dimensions=${category.data.dimensions['height']}x${category.data.dimensions['width']}x${category.data.dimensions['length']},${category.data.dimensions['weight']}`);
            const shipping = await mlPublicApi.get(`users/${account}/shipping_options/free?item_id=${mlItemId}`);
            setShippingData(shipping.data);
            const listing = await mlPublicApi.get(`sites/MLB/listing_prices?price=${item.data['price']}&category_id=${item.data['category_id']}`);
            setListingData(listing.data);
        };

        const handleAccountSwitch = async (e) => {
            const re = new RegExp('MLB[-]?([0-9]*)');
            const r = mlItemLink.match(re);
            if (!r) {
                return
            }
            const mlItemId = 'MLB' + mlItemLink.match(re)[1];
            setAccount(e.target.value);
            const shipping = await mlPublicApi.get(`users/${e.target.value}/shipping_options/free?item_id=${mlItemId}`);
            setShippingData(shipping.data);
        };

        const handleItemIdChange = (e) => {
            setbuttonDisabled(false);
            setMlItemLink(e.target.value);
        };

        const handleFocus = (e) => {
            e.target.select();
        };
        return (
            <Fragment>
                <Row>
                    <Col md={{span: 6, offset: 3}} className="my-2">
                        {alert()}
                    </Col>
                </Row>
                <h4>Simulador de Custos</h4>
                <Form inline>
                    <InputGroup className="m-sm-2">
                        <FormControl
                            placeholder="Código do Anúncio"
                            aria-label="Código do Anúncio"
                            aria-describedby="basic-addon2"
                            value={mlItemLink}
                            onChange={handleItemIdChange}
                            onKeyDown={cancelSubmit}
                            onFocus={handleFocus}
                        />
                        <InputGroup.Append>
                            <Button
                                onClick={handleSearch}
                                variant="primary"
                                disabled={buttonDisabled}
                            >Simular</Button>

                        </InputGroup.Append>
                    </InputGroup>
                </Form>
                <Form inline>
                    <Form.Control
                        as="select"
                        className="m-sm-2"
                        id="sellerSelector"
                        onKeyDown={cancelSubmit}
                        onChange={handleAccountSwitch}>
                        {
                            props.destinationAccounts.map(
                                (account) => {
                                    return (<option key={account.mlSellerId}
                                                    value={account.mlSellerId}>{account.nickname}</option>)
                                }
                            )
                        }
                    </Form.Control>
                    <InputGroup className="m-sm-2">
                        <Form.Label className="my-1 mr-2" htmlFor="taxInput">
                            Porcentagem Imposto
                        </Form.Label>
                        <FormControl
                            id="taxInput"
                            placeholder="Imposto Simples ex: 0.04 (4%)"
                            aria-label="Imposto Simples ex: 0.04 (4%)"
                            aria-describedby="basic-addon2"
                            value={taxPercentage}
                            onChange={e => setTaxPercentage(e.target.value)}
                            onKeyDown={cancelSubmit}
                        />
                    </InputGroup>
                    <InputGroup className="m-sm-2">
                        <Form.Label className="my-1 mr-2">
                            Custo Mercadoria
                        </Form.Label>
                        <FormControl
                            placeholder="Custo Mercadoria Vendida"
                            aria-label="Custo Mercadoria Vendida"
                            aria-describedby="basic-addon2"
                            value={itemCost}
                            onChange={e => setItemCost(e.target.value)}
                            onKeyDown={cancelSubmit}
                        />
                    </InputGroup>
                </Form>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>-</th>
                        <th>Clássico</th>
                        <th>Premium</th>
                    </tr>
                    </thead>
                    <tbody>
                    {table.map(renderRow)}
                    </tbody>
                </Table>
            </Fragment>
        );
    }
;

const mapStateToProps = state => {
    return {
        blacklist: state.user.blacklist,
        blacklistOperationSuccess: state.user.blacklistOperationSuccess,
        blacklistOperationError: state.user.blacklistOperationError,
        destinationAccounts: state.replication.destinationAccounts,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        updateBlacklist: (mlSellerId) => dispatch(actionTypes.updateBlacklist(mlSellerId)),
        blockUser: (mlSellerId, mlNicknameToBlock) => dispatch(actionTypes.blockUser(mlSellerId, mlNicknameToBlock)),
        unblockUser: (mlSellerId, mlBlockedUserId) => dispatch(actionTypes.unblockUser(mlSellerId, mlBlockedUserId)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceSimulation);

import {Fragment, useEffect} from 'react'
import Button from 'react-bootstrap/Button'
import {connect} from "react-redux";
import * as actionTypes from "../../../../store/actions";
import {useHistory} from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";

const ReplicationConfirmation = (props) => {
    const history = useHistory();
    useEffect(() => {
        props.updateBalance()
    }, []);


    const clickHandler = (e) => {
        e.target.blur();
        props.createReplicationJob();
        props.clearSelected();
        history.push("/app/replication/result");
    };

    const confirmation = () => {
        return (<Card>
            <Card.Body>
                <Card.Title>O Mercadolivre não permite anúncios duplicados</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">Anúncios similares que oferecem condições de pagamento ou
                    frete diferentes são permitidos</Card.Subtitle>
                <Card.Text>
                    Anúncios Selecionados <Badge variant="primary">{props.selectedItems.length}</Badge><br/>
                    Conta de Destino <Badge variant="primary">{props.destinationSellerName}</Badge>
                </Card.Text>
                <Button onClick={clickHandler} variant="primary">Confirmar</Button>
            </Card.Body>
        </Card>)
    };

    const alert = () => {
        let m = null;
        if (props.creditsExpired) {
            m = "Não há créditos disponíveis"
        } else if (props.credits < props.selectedItems.length) {
            m = "Não há créditos suficientes para os itens selecionados"
        } else if (props.selectedItems.length === 0) {
            m = "Selecione pelo menos um anúncio para replicar"
        } else if (props.destinationSellerName === '') {
            m = "Selecione uma conta de destino"
        }

        if (m !== null) {
            return (<Alert className='p-2 m-2' variant='danger'>{m}</Alert>)
        }

    };
    return (
        <Fragment>
            {
                props.selectedItems.length > 0 && props.destinationSellerName !== '' && props.credits >=
                props.selectedItems.length && !props.creditsExpired ? confirmation() : alert()
            }
        </Fragment>
    )
};

const mapStateToProps = state => {
    return {
        selectedItems: state.item.selectedItems,
        destinationSellerName: state.replication.destinationSellerName,
        credits: state.user.credits,
        expirationDate: state.user.expirationDate,
        creditsExpired: state.user.creditsExpired,
    }
};


const mapDispatchToProps = dispatch => {
    return {
        createReplicationJob: () => dispatch(actionTypes.createReplicationJob()),
        updateBalance: () => dispatch(actionTypes.updateBalance()),
        clearSelected: () => dispatch(actionTypes.clearSelected()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ReplicationConfirmation);


import * as actionTypes from './actionsTypes';
import {api} from '../../services/api'

export const setDestinationSellerId = (mlSellerId, nickname = null) => {
    return {
        type: actionTypes.SET_DESTINATION_SELLER_ID,
        payload: {
            mlSellerId,
            nickname
        }
    }
};

export const setAccounts = (value) => {
    return {
        type: actionTypes.UPDATE_REPLICATION_STATE,
        payload: {
            destinationAccounts: value
        }
    }
};

export const setReplicationJobId = (value) => {
    return {
        type: actionTypes.UPDATE_REPLICATION_STATE,
        payload: {
            replicationJobId:value
        }
    }
};

export const setFailedJobCreation = (failed) => {
    return {
        type: actionTypes.UPDATE_REPLICATION_STATE,
        payload: {
            failedJobCreation: failed
        }
    }
};

export const setLoadedAccounts = (value) => {
    return {
        type: actionTypes.UPDATE_REPLICATION_STATE,
        payload: {
            loadedAccounts: value
        }
    }
};


export const initAccounts = () => {
    return dispatch => {
        api.get('/ml/getAccounts').then
        ((response) => {
            dispatch(setLoadedAccounts(true));
            if (response.data.results.length > 0) {
                dispatch(setDestinationSellerId(response.data.results[0].mlSellerId, response.data.results[0].nickname));
                dispatch(setAccounts(response.data.results));
            }
        }).catch(error => {
            //TODO: handle account error
            console.log(error)
        })
    }
};

export const createReplicationJob = () => {
    return (dispatch, getState) => {
        const state = getState();
        const {selectedItems} = state.item;
        const {destinationSellerId} = state.replication;

        api.post(`/ml/createReplicationJob`, {itemsToReplicate: selectedItems, mlSellerId: destinationSellerId})
            .then(
                (response) => {
                    dispatch(setFailedJobCreation(false));
                    dispatch(setReplicationJobId(response.data.id));
                })
            .catch(error => {
                dispatch(setFailedJobCreation(true));
            })
    }
};
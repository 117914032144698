import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Badge from 'react-bootstrap/Badge'
import Media from 'react-bootstrap/Media'
import styles from './Item.module.css';
import React from "react";
import {connect} from "react-redux";
import { FaTruck, FaExternalLinkAlt } from 'react-icons/fa';
import { BsBarChartFill } from 'react-icons/bs';

import * as actionTypes from '../../../store/actions/index';

const item = (props) => {
    let mediaStyles = ["shadow", styles.formatMedia];

    const listingTypeMap = {
        gold_pro: 'Premium',
        gold_premium: 'Diamante',
        gold_special: 'Clássico',
        gold: 'Ouro',
        silver: 'Prata',
        bronze: 'Bronze',
        free: 'Grátis'
    };

    const handleImageClick = (itemId) => {
        const currentCheckState = props.selectedItems.includes(itemId);

        if (currentCheckState) {
            props.deleteItem(props.itemId);
        } else {

            props.addItem(props.itemId);
            props.computeAllItemsSelected();
        }
    };

    return (
        <Media className={mediaStyles.join(' ')}>
            <div className={styles.formatImage} onClick={() => handleImageClick(props.itemId)}>
                <input type='checkbox' checked={props.selectedItems.includes(props.itemId)} readOnly={true}/>
                <img
                    width={100}
                    height={100}
                    src={props.thumbnail}
                    alt={props.title}
                />
            </div>
            <Media.Body>
                <h6 style={{display: 'inline'}}><a href={props.permalink} rel="noreferrer" target="_blank">{props.title} <FaExternalLinkAlt/></a></h6>
                <Row>
                    <Col>
                        <h5>R$ {props.price}</h5>
                        <h5>{props.availableQuantity} un</h5>
                    </Col>
                    <Col className="col-sm-4"><p>{
                        props.freeShipping ? <Badge variant="success"><FaTruck/> Frete Grátis</Badge> : null
                    } {
                        props.listingType === 'gold_pro' ?
                            <Badge variant="secondary"><BsBarChartFill/> {listingTypeMap[props.listingType]}</Badge> : null
                    }</p>
                        </Col>
                </Row>
            </Media.Body>
        </Media>
    )
};

const mapDispatchToProps = dispatch => {
    return {
        addItem: (itemId) => dispatch(actionTypes.addItems([itemId])),
        deleteItem: (itemId) => dispatch(actionTypes.deleteItems([itemId])),
        computeAllItemsSelected: (itemId) => dispatch(actionTypes.computeAllItemsSelected())
    }
};

const mapStateToProps = state => {
    return {
        selectedItems: state.item.selectedItems,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(item);
import React, {useState, useEffect, Fragment} from 'react';
import {connect} from "react-redux";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Alert from "react-bootstrap/Alert";
import * as actionTypes from "../../../store/actions";
import { useSelector, useDispatch } from 'react-redux'
import {api} from '../../../services/api'
import {initAccounts, re} from "../../../store/actions";


export default function MlAccount() {
    const dispatch = useDispatch()
    const authenticationError = useSelector((state) => state.authentication.authenticationError)
    const authenticationMessage = useSelector((state) => state.authentication.authenticationMessage)
    const destinationAccounts = useSelector((state) => state.replication.destinationAccounts)


    useEffect(() => {
        dispatch(initAccounts())
    }, []);

    useEffect(() => {
        api.get('ml/info').then((response) => {
            setAuthConfig({oauthUrl: response.data.oauthUrl})
        });
    }, []);

    const [authConfig, setAuthConfig] = useState({
        oauthUrl: null,
    });

    const alert = () => {
        if (authenticationError !== null) {
            return (
                <Alert variant={authenticationError ? 'danger' : 'success'}>{authenticationMessage}</Alert>
            )
        }
    };

    return (
        <Fragment>
            {alert()}
            <h4>Contas Autorizadas</h4>
            <ListGroup className='my-2'>
            {
                destinationAccounts.map(
                    (account) => {
                        return (
                            <ListGroup.Item key={account.mlSellerId}>{account.nickname}</ListGroup.Item>
                        )
                    }
                )
            }

            </ListGroup>
            <Button href={authConfig.oauthUrl} disabled={authConfig.oauthUrl === null}>Adicionar Conta</Button>
            <Card className='my-2'>
                <Card.Body><b>Atenção!</b><br/>Para adicionar uma conta diferente,<br/> é necessário que você não esteja logado no Mercadolivre em uma conta já autorizada.<br/>Saia da conta atual no <a href='https://www.mercadolivre.com.br/' rel="noreferrer" target='_blank'>Mercadolivre</a> e tente autorizar a conta novamente.</Card.Body>
            </Card>
        </Fragment>
    );
}
import React, {useState, useEffect, Fragment} from 'react';
import {connect} from "react-redux";
import {getToken} from './../../../services/auth'
import {baseUrl} from './../../../services/api'
import EventSource from 'eventsource'
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import Alert from "react-bootstrap/Alert";
import {MdCloudDone, MdError} from 'react-icons/md';
import Spinner from 'react-bootstrap/Spinner'
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const ItemReplicatorResult = (props) => {
        let eventSourceInitDict = {headers: {'Authorization': `bearer ${getToken()}`}};

        const [result, setResult] = useState({items: []});

        const [loading, setLoading] = useState(true);

        const [message, setMessage] = useState({text: null, error: false});

        const renderRow = (item) => {
            let description;
            const success = item.code === 201;
            if (success){
                description = <a href={item.results.permalink}  rel="noreferrer" target='_blank'>{item.results.id} - {item.results.title}</a>
            }else{
                if (item.results.cause){
                    description = `${item.results.message} - ${item.results.cause[0].message}`
                }else{
                    description = `${item.results.message}`
                }

            }

            return (
                <tr key={item.original.itemId}>
                    <td>{success ? <Badge variant='success'><MdCloudDone/> Sucesso</Badge> :
                        <Badge variant='danger'><MdError/> Erro</Badge>}</td>
                    <td><a href={item.original.permalink} rel="noreferrer" target='_blank'>{item.original.itemId}</a></td>
                    <td>{description}</td>
                </tr>
            )
        };

        const alert = () => {
            let m = null;
            if (props.failedJobCreation) {
                m = "Não foi possível replicar os anúncios, tente novamente..."
            } else if (message.error) {
                m = message.text
            }
            if (m) {
                return (<Alert variant='danger'>{m}</Alert>)
            }
        };

        useEffect(() => {
            if (props.replicationJobId) {
                setLoading(true);
                const es = new EventSource(`${baseUrl}/ml/replicate/${props.replicationJobId}`, eventSourceInitDict);

                es.addEventListener('message', function (e) {
                    setMessage({text: null, error: false});
                    const data = JSON.parse(e.data);
                    console.log(data);
                    setResult(prevState => {
                        return {
                            items: prevState.items.concat(data)
                        }
                    });
                }, false);

                es.addEventListener('close', function (e) {
                    setLoading(false);
                    es.close();
                }, false);

                es.addEventListener('error', function (e) {
                    setLoading(false);
                    const data = JSON.parse(e.data);
                    setMessage({text: e.data.message, error: true});
                    es.close();
                }, false);

                es.onerror = function (err) {
                    setLoading(false);
                    es.close();
                    if (err) {
                        console.log(err);
                    }
                };
            }
        }, [props.replicationJobId]);

        return (
            <Fragment>
                {
                    loading ?
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner> : null
                }
                <Row>
                    <Col md={{span: 6, offset: 3}} className="my-2">
                        {alert()}
                    </Col>
                </Row>
                {
                    props.failedJobCreation || message.error ? null :

                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>Situação</th>
                                <th>Anúncio Original</th>
                                <th>Anúncio Novo</th>
                            </tr>
                            </thead>
                            <tbody>
                            {result.items.map(renderRow)}
                            </tbody>
                        </Table>
                }

            </Fragment>
        );

    }
;

const mapStateToProps = state => {
    return {
        selectedItems: state.item.selectedItems,
        destinationSellerId: state.replication.destinationSellerId,
        replicationJobId: state.replication.replicationJobId,
        failedJobCreation: state.replication.failedJobCreation,
    }
};

export default connect(mapStateToProps)(ItemReplicatorResult);

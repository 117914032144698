import * as actionTypes from '../actions/actionsTypes'
import {updateObject} from './../utility'

const initialState = {
    nickname: '',
    sort: 'relevance',
    offset: 0,
    limit: 10,
    searchButtonDisabled: false,
    titleFilter: ''
};

const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        case  actionTypes.SET_SORT :
            return updateObject(state, {sort: action.payload.value});
        case actionTypes.SET_OFFSET :
            return updateObject(state, {offset: action.payload.value});
        case actionTypes.SET_LIMIT :
            return updateObject(state, {limit: action.payload.value});
        case actionTypes.SET_NICKNAME :
            return updateObject(state, {nickname: action.payload.value});
        case actionTypes.SET_SEARCH_BOX_DISABLED :
            return updateObject(state, {searchButtonDisabled: action.payload.value});
        case actionTypes.SET_SEARCHING :
            return updateObject(state, {searching: action.payload.value});
        case actionTypes.SET_TITLE_FILTER :
            return updateObject(state, {titleFilter: action.payload.value});
        case actionTypes.CLEAR_STATE :
            return initialState;
        default:
            return state;
    }
};

export default searchReducer;
import React, {useState, useEffect, Fragment} from 'react';
import {connect} from "react-redux";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import * as actionTypes from "../../../store/actions";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";

const BlockUser = (props) => {
        const [account, setAccount] = useState(null);
        const [lastSearchedAccount, setLastSearchedAccount] = useState(null);
        const [blockNickname, setBlockNickname] = useState('');

        useEffect(() => {
            if (props.destinationAccounts.length > 0 && account === null) {
                const accountId = props.destinationAccounts[0].mlSellerId;
                setAccount(accountId);
                setLastSearchedAccount(accountId);
                props.updateBlacklist(props.destinationAccounts[0].mlSellerId);
            }
        }, [props.destinationAccounts]);

        const renderRow = (user) => {
            return (
                <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>{user.nickname}</td>
                    <td><Button onClick={(e)=>{unblock(user.id)}} variant='danger'>Desbloquear</Button></td>
                </tr>
            )
        };

        const alert = () => {
            if (props.blacklistOperationSuccess !== null) {
                return (<Alert variant={props.blacklistOperationSuccess?'success':'danger'}>{props.blacklistOperationError}</Alert>)
            }
        };

        const cancelSubmit = (e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
            }
        };

        const handleSearch = (e) => {
            if (account !== lastSearchedAccount) {
                props.updateBlacklist(account);
                setLastSearchedAccount(account);
            }
        };

        const handleBlock = (e) => {
            props.blockUser(account, blockNickname.trim());
        };

        const unblock = (blockedUserId) => {
            props.unblockUser(account, blockedUserId);
        };

        return (
            <Fragment>
                <Row>
                    <Col md={{span: 6, offset: 3}} className="my-2">
                        {alert()}
                    </Col>
                </Row>
                <h4>Bloqueador de Compradores</h4>
                <Form inline>
                    <InputGroup className="m-sm-2">
                        <FormControl
                            placeholder="Usuário do Comprador"
                            aria-label="Usuário do Comprador"
                            aria-describedby="basic-addon2"
                            value={blockNickname}
                            onChange={(e) => {
                                setBlockNickname(e.target.value)
                            }}
                            onKeyDown={cancelSubmit}
                        />
                        <InputGroup.Append>
                            <Button
                                onClick={handleBlock}
                                variant="primary">Bloquear</Button>
                        </InputGroup.Append>
                    </InputGroup>
                    <Form.Control
                        as="select"
                        className="m-sm-2"
                        id="sellerSelector"
                        onKeyDown={cancelSubmit}
                        onChange={e => setAccount(e.target.value)}>
                        {
                            props.destinationAccounts.map(
                                (account) => {
                                    return (<option key={account.mlSellerId}
                                                    value={account.mlSellerId}>{account.nickname}</option>)
                                }
                            )
                        }

                    </Form.Control>
                    <Button onClick={handleSearch}
                            disabled={account === lastSearchedAccount}>Listar</Button>
                </Form>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Id</th>
                        <th>Nome</th>
                        <th>Opções</th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.blacklist.map(renderRow)}
                    </tbody>
                </Table>
            </Fragment>
        );
    }
;

const mapStateToProps = state => {
    return {
        blacklist: state.user.blacklist,
        blacklistOperationSuccess: state.user.blacklistOperationSuccess,
        blacklistOperationError: state.user.blacklistOperationError,
        destinationAccounts: state.replication.destinationAccounts,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        updateBlacklist: (mlSellerId) => dispatch(actionTypes.updateBlacklist(mlSellerId)),
        blockUser: (mlSellerId, mlNicknameToBlock) => dispatch(actionTypes.blockUser(mlSellerId, mlNicknameToBlock)),
        unblockUser: (mlSellerId, mlBlockedUserId) => dispatch(actionTypes.unblockUser(mlSellerId, mlBlockedUserId)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockUser);

import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    authenticationMessage: null,
    authenticationError: null
}

export const authenticationSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setMessage: (state, action) => {
            state.authenticationMessage = action.payload.message
            state.authenticationError = action.payload.error
        },
        reset: () => initialState
    },
})

// Action creators are generated for each case reducer function
export const {setMessage, reset} = authenticationSlice.actions

export default authenticationSlice.reducer
import Item from './Item/Item';

const items = (props) => props.items.map((item, index) => {
        return (
            <Item
                key={item.id}
                title={item.title}
                itemId={item.id}
                discount={item.discount}
                freeShipping={item.freeShipping}
                listingType={item.listingType}
                price={item.price}
                originalPrice={item.originalPrice}
                availableQuantity={item.availableQuantity}
                thumbnail={item.thumbnail}
                permalink={item.permalink}
            />
        )
    }
);

export default items;

import axios from "axios";
import {getToken, logout} from "./auth";
const baseUrl = process.env.REACT_APP_API_BASE_URL || "https://api.mercadodata.com.br";

const mlPublicApi = axios.create({
    baseURL: 'https://api.mercadolibre.com'
});

const api = axios.create({
    baseURL: baseUrl
});

api.interceptors.request.use(async config => {
    const token = getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
});

api.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        logout();
    }
    return Promise.reject(error);
});

export {api, baseUrl, mlPublicApi}

import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import * as actionTypes from "../../../store/actions";
import {api} from './../../../services/api'
import qs from 'qs';
import Spinner from "react-bootstrap/Spinner";
import { useHistory } from "react-router-dom";

const MlAuthorization = (props) => {
    const history = useHistory();

    useEffect(() => {
        const query = qs.parse(props.location.search, {ignoreQueryPrefix: true});
        if (query.code) {
            api.post('ml/authorization', {code: query.code}).then((response) => {
                props.setAuthenticationMessage(`Conta ${response.data.nickname} vinculada com sucesso!`, false);
                history.push("/app/account");
            }).catch((e)=>{
                props.setAuthenticationMessage(e.response.data.message, true);
                history.push("/app/account");
            });
        }
    }, []);

    const [authConfig, setAuthConfig] = useState({
        oauthUrl: null,
    });

    return (
        <Row >
            <Col md={{span: 6, offset: 3}} className="my-2">
                <Card>
                    <Card.Body>
                        <Card.Title><Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner> Autenticando</Card.Title>
                        <Card.Text>
                            Aguarde, em breve você será redirecionado...
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};


const mapDispatchToProps = dispatch => {
    return {
        setAuthenticationMessage: (message, error) => dispatch(actionTypes.setAuthenticationMessage(message, error)),
    }
};

export default connect(null, mapDispatchToProps)(MlAuthorization);

import * as actionTypes from "./actionsTypes";

export const setAuthenticationMessage = (message, error) => {
    return {
        type: actionTypes.SET_AUTHENTICATION_MESSAGE,
        payload: {
            message,
            error
        }
    }
};
export const SET_ITEMS = 'SET_ITEMS';
export const ADD_ITEMS = 'ADD_ITEMS';
export const DELETE_ITEMS = 'DELETE_ITEMS';
export const FETCH_ITEMS_FAILED = 'FETCH_ITEMS_FAILED';
export const CLEAR_LIST = 'CLEAR_LIST';
export const SET_ALL_ITEMS_SELECTED = 'SET_ALL_ITEMS_SELECTED';
export const COMPUTE_ALL_ITEMS_SELECTED = 'COMPUTE_ALL_ITEMS_SELECTED';
export const SET_SAME_SELLER_LISTING_NOT_ALLOWED_ERROR = 'SET_SAME_SELLER_LISTING_NOT_ALLOWED_ERROR';


export const SET_SORT = 'SET_SORT';
export const SET_OFFSET = 'SET_OFFSET';
export const SET_LIMIT = 'SET_LIMIT';
export const SET_NICKNAME = 'SET_NICKNAME';
export const SET_SEARCH_BOX_DISABLED = 'SET_SEARCH_BOX_DISABLED';
export const SET_SEARCHING = 'SET_SEARCHING';
export const SET_TITLE_FILTER = 'SET_TITLE_FILTER';

export const SET_DESTINATION_SELLER_ID = 'SET_DESTINATION_SELLER_ID';

//Authentication Reducer
export const SET_AUTHENTICATION_MESSAGE = 'SET_AUTHENTICATION_MESSAGE';

export const UPDATE_USER_STATE = 'UPDATE_USER_STATE';
export const UPDATE_REPLICATION_STATE = 'UPDATE_REPLICATION_STATE';
export const UPDATE_ITEM_STATE = 'UPDATE_ITEM_STATE';



//ALL
export const CLEAR_STATE = 'CLEAR_STATE';